import { motion } from "framer-motion"
import TransitionLink from 'gatsby-plugin-transition-link'
import React from 'react'
import Bread from '../components/bread'
import Formularz from '../components/formularz/formularz'
import Seo from '../components/seo.js'
import UslugaRzad from '../components/uslugi/usluga_rzad'
import UslugaRzadMobile from '../components/uslugi/usluga_rzad_mobile'
import branding from '../images/podstrony/uslugi/okladka_branding@2x.png'
import design from '../images/podstrony/uslugi/okladka_design@2x.png'
import media from '../images/podstrony/uslugi/okladka_media@2x.png'
import uslugi from '../images/seo/uslugi.jpg'









class Uslugi extends React.Component {
  constructor(props) {
    super(props)
    this.zmienChlebora = this.zmienChlebora.bind(this)
    this.state = {
      breadCrumb: false,
      chowaj: false,
      chowaj2: false,
      chowaj3: false,
      tekst: false,
      mobile: false,
    }
    this.eUsluga = React.createRef();
    this.main = React.createRef();
  }


  componentDidMount() {
    this.test = this.main.current.getBoundingClientRect();

    if(this.test.width < 1025) {
        this.setState({
            mobile: true,
        })
        
    }


}


  zmienChlebora = () => {
    if(this.state.mobile) {
    
   
    }
    else {
      this.setState({
        breadCrumb: !this.state.breadCrumb
      })
    }
 
   
  }


  handleClick = () => {
    if(this.state.mobile) {
    
   
    }
    else {
      this.eUsluga.current.toggleFullScreen();
    }
 
  }


  chowajReszte = () => {
    if(this.state.mobile) {
    
   
    }
    else {
      this.setState({
        tekst: !this.state.tekst,
        chowaj: !this.state.chowaj,
        breadCrumb: !this.state.breadCrumb,
      })
    }
    
  }

  chowajReszte2 = () => {
    if(this.state.mobile) {
    
   
    }
    else {
      this.setState({
        tekst: !this.state.tekst,
        chowaj2: !this.state.chowaj,
        breadCrumb: !this.state.breadCrumb,
      })
    }

   
  }

  chowajReszte3 = () => {
    if(this.state.mobile) {
    
   
    }
    else {
      this.setState({
        tekst: !this.state.tekst,
        chowaj3: !this.state.chowaj,
        breadCrumb: !this.state.breadCrumb,
      })
    }
    
  }
  



  

 
 

  render() {


    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: 100 },
     }


     if(this.state.mobile) {
      return (<>
    
        <motion.div ref={this.main} className={this.state.chowaj || this.state.chowaj2 || this.state.chowaj3 ? (
          "chowaj-uslugi"
        ) : (
           ""
          )}
          initial={this.props.entry.state}
          animate={
            (this.props.transitionStatus === "exiting" && (this.props.entry.state.past !== "/" || this.props.entry.state.past !== "/uslugi/"))  ? {x: 0} :  {x: 0}
          }
          transition={{
            duration: 0.8,
          }}>
        <Seo title="USŁUGI" description="Łączymy wiedzę z obszaru komunikacji marketingowej z najlepszym warsztatem wizualnym, by zapewnić Ci kompleksową obsługę wizerunku Twojego biznesu." image={uslugi} />
        <div className={this.state.breadCrumb ? (
          "breadcrumb-swap breadcrumb-hide"
        ) : (
            "breadcrumb-swap"
          )} >
          <Bread title="USŁUGI" tekst1="KONTAKT" link1="/formularz" tekst2="PROJEKTY" link2="/projekty" />
          
        </div>
        
        <div className="podstrona-wrapper">
        <div className="uslugi-intro-tekst">
        <h6>Projektujemy wyjątkowe marki</h6>
        <h4>
        Łączymy wiedzę z obszaru komunikacji marketingowej z najlepszym warsztatem wizualnym, by zapewnić Ci kompleksową obsługę wizerunku Twojego biznesu – wszędzie gdzie się pojawiasz. Słuchamy Twoich klientów, inspirujemy rozwiązania, które odpowiedzą na ich potrzeby. Tworzymy angażujące doświadczenia i wartościowe interakcje. Projektujemy wyjątkowe marki.</h4>
        </div>
         
  
       
        </div>
        
        
        <motion.div className="uslugi-lista">
            <motion.div
              className={this.state.chowaj ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
              variants={item}
  
              onClick={this.chowajReszte}
  >
                <TransitionLink 
                to="/uslugi/branding"
                exit={{
                  length: 1.5,
                
                  state: { },
                }}
  
               
                entry={{
                  length: 1.5,
           
                  state:  {},
  
                }}>
              <UslugaRzadMobile img={branding}  usluga="BRANDING" ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
  
            <motion.div
              className={this.state.chowaj2 ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
             
              onClick={this.chowajReszte2}
      >
                  <TransitionLink 
                to="/uslugi/design"
                exit={{
                  length: 1.5,

                  state: { },
                }}
  
               
                entry={{
                  length: 1.5,

                  state:  {},
  
                }}>
              <UslugaRzadMobile img={design}   usluga="DESIGN" ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
  
            <motion.div
              className={this.state.chowaj3 ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
              onClick={this.chowajReszte3}>
                 <TransitionLink 
                to="/uslugi/media"
                exit={{
                  length: 1.5,
                
                  state: { },
                }}
  
               
                entry={{
                  length: 1.5,
                
                  state:  {},
  
                }}>
              <UslugaRzadMobile usluga="MEDIA" img={media} ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
          </motion.div>
        
          <Formularz />
          </motion.div>
          </>
  
  
          
      
      )
     }

    else {
      return (<>
    
        <motion.div ref={this.main} className={this.state.chowaj || this.state.chowaj2 || this.state.chowaj3 ? (
          "chowaj-uslugi"
        ) : (
           ""
          )}
          initial={this.props.entry.state}
          animate={
            (this.props.transitionStatus === "exiting" && (this.props.entry.state.past !== "/" || this.props.entry.state.past !== "/uslugi/"))  ? {x: 0} :  {x: 0}
          }
          transition={{
            duration: 0.8,
          }}>
          <Seo title="USŁUGI" description="Łączymy wiedzę z obszaru komunikacji marketingowej z najlepszym warsztatem wizualnym, by zapewnić Ci kompleksową obsługę wizerunku Twojego biznesu – wszędzie gdzie się pojawiasz." image={uslugi} />
        <div className={this.state.breadCrumb ? (
          "breadcrumb-swap breadcrumb-hide"
        ) : (
            "breadcrumb-swap"
          )} >
          <Bread title="USŁUGI" tekst1="KONTAKT" link1="/formularz" tekst2="PROJEKTY" link2="/projekty" />
          
        </div>
        
        <div className="podstrona-wrapper">
        <div className="uslugi-intro-tekst">
        <h6>Projektujemy wyjątkowe marki</h6>
        <h4>
       Łączymy wiedzę z obszaru komunikacji marketingowej z najlepszym warsztatem wizualnym, by zapewnić Ci kompleksową obsługę wizerunku Twojego biznesu – wszędzie gdzie się pojawiasz. Słuchamy Twoich klientów, inspirujemy rozwiązania, które odpowiedzą na ich potrzeby. Tworzymy angażujące doświadczenia i wartościowe interakcje. Projektujemy wyjątkowe marki.</h4>
        </div>
         
  
       
        </div>
        
        
        <motion.div className="uslugi-lista">
            <motion.div
              className={this.state.chowaj ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
              variants={item}
  
              onClick={this.chowajReszte}
  >
                <TransitionLink 
                to="/uslugi/branding"
                exit={{
                  length: 1.5,
                  delay: 1,
                  state: { x: 0, past: 'uslugi'},
                }}
  
               
                entry={{
                  length: 1.5,
                  appearAfter: 1,
                  state:  {x: 0, past: 'uslugi'},
  
                }}>
              <UslugaRzad img={branding}  usluga="BRANDING" ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
  
            <motion.div
              className={this.state.chowaj2 ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
             
              onClick={this.chowajReszte2}
      >
                  <TransitionLink 
                to="/uslugi/design"
                exit={{
                  length: 1.5,
                  delay: 1,
                  state: { x: 0, past: 'uslugi'},
                }}
  
               
                entry={{
                  length: 1.5,
                  appearAfter: 1,
                  state:  {x: 0, past: 'uslugi'},
  
                }}>
              <UslugaRzad img={design}   usluga="DESIGN" ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
  
            <motion.div
              className={this.state.chowaj3 ? (
                "usluga-wybrana"
              ) : (
                  ""
                )}
              onClick={this.chowajReszte3}>
                 <TransitionLink 
                to="/uslugi/media"
                exit={{
                  length: 1.5,
                  delay: 1,
                  state: { x: 0, past: 'uslugi'},
                }}
  
               
                entry={{
                  length: 1.5,
                  appearAfter: 1,
                  state:  {x: 0, past: 'uslugi'},
  
                }}>
              <UslugaRzad usluga="MEDIA" img={media} ref={this.eUsluga} zmienChlebora={this.zmienChlebora} />
              </TransitionLink>
            </motion.div>
          </motion.div>
        
          <Formularz />
          </motion.div>
          </>
  
  
          
      
      )
    }

   
    
  }
}

export default Uslugi


